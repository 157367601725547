<template>
  <div class="page-content">
    <div class="container">
        <section class="pl-2 pr-2 overflow-section">
            <h1>Scoop, there it is!</h1>
            <p>Thank you for your order. We’ll see you in a bit!</p>
            <p>Keep an eye out for your confirmation email.</p>
            <div class="controls">
                <Router-link :to="$options.links.home" class="btn">Want some more?</Router-link>
            </div>
        </section>
    </div>
  </div>
</template>

<script>
import ROUTES from '@/constans/route';

export default {
  links: {
    home: ROUTES.HOME,
  },
  name: 'ThankYou',
};
</script>

<style scoped>
h1 {
    font-size: 28px;
}

.overflow-section {
    text-align: center;
    font-size: 14pt;
}
</style>

<style>
.logo.thank-you {
    margin-top: 100px;
    margin-bottom: 50px;
}
.thank-you img {
    width: 115px;
}
</style>
